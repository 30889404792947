import React from "react";
import { Box, BoxProps, keyframes } from "@chakra-ui/react";
import styled from "@emotion/styled";

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const NormalCardStyle = styled(Box)`
    border-radius: 24px;
    padding: 24px;
    background-color: #171717;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

export const NormalCard = (props: BoxProps) => {
    return <NormalCardStyle {...props}></NormalCardStyle>;
};

const CardStyle = styled(Box)`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
    padding: 20px;
    &:before {
        content: "";
        position: absolute;
        width: 100px;
        background: linear-gradient(
            to top,
            rgb(0, 183, 255),
            rgb(255, 48, 255)
        );
        height: 200%;
        animation: ${rotate} 3s linear infinite;
        transition: all 0.2s linear;
    }
    &:after {
        content: "";
        position: absolute;
        background: #171717;

        inset: 5px;
        border-radius: 15px;
    }
    & > div {
        position: relative;
        z-index: 1;
    }
`;

export const Card = (props: BoxProps) => {
    return (
        <CardStyle
            bg="#171717"
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            borderRadius="20px"
            padding={"20px"}
            _before={{
                content: '""',
                position: "absolute",
                width: "100px",
                bgGradient: "linear(to-t, rgb(0, 183, 255), rgb(255, 48, 255))",
                height: "130%",
                animation: `${rotate} 3s linear infinite`,
                transition: "all 0.2s linear",
            }}
            _after={{
                content: '""',
                position: "absolute",
                bg: "#171717",
                inset: "5px",
                borderRadius: "15px",
            }}
            {...props}
        ></CardStyle>
    );
};
