import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { HashRouter } from "react-router-dom";
import { WagmiConfig } from "wagmi";
import { GlobalStyles } from "./skyConstants";
import AppRoutes, { ScrollToTop } from "./Routes";
import theme from "./theme";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import "./skyConstants/i18n";
import { bscTestnet, klaytn } from "viem/chains";
const kkk = {
    ...klaytn,
    rpcUrls: {
        ...klaytn.rpcUrls,
        default: {
            http: ["https://rpc.ankr.com/klaytn"],
        },
        public: {
            http: ["https://rpc.ankr.com/klaytn"],
        },
    },
};
const chains: any = [kkk];

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;

// 2. Create wagmiConfig
const metadata = {
    name: "Web3Modal",
    description: "Web3Modal Example",
    url: "https://web3modal.com",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const wagmiConfig = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
    enableCoinbase: false,
    enableInjected: false,
});
createWeb3Modal({
    defaultChain: klaytn,

    wagmiConfig,
    projectId,
    chains,

    featuredWalletIds: [
        "3c2c985c0adff6f46a0d0e466b3924ed8a059043882cd1944ad7f2adf697ed54",
        "c9f7ff50ade14bbdeb13249b17b3ecf3e36ba12def7ac4fdbf00352c4e4894a2",
    ],
});

root.render(
    <Box>
        <ChakraProvider theme={theme}>
            <Global styles={GlobalStyles} />
            <HashRouter>
                <WagmiConfig config={wagmiConfig}>
                    <Fragment>
                        <ScrollToTop />
                        <AppRoutes />
                    </Fragment>
                </WagmiConfig>
            </HashRouter>
        </ChakraProvider>
    </Box>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
