import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            Copied: "Copied!",
            Documentation: "Documentation",
            TVL: "TVL",
            Contract: "Contract",
            "My Wallet": "My Wallet",
            "Your Ducks": "Your Ducks",
            DUCKS: "DUCKS",
            "Your Rewards": "Your Rewards",
            COMPOUND: "COMPOUND",
            CLAIM: "CLAIM",
            "Nutritional Values": "Nutritional Values",
            "Daily Return": "Daily Return",
            "Dev Fee": "Dev Fee",
            "Referral Link": "Referral Link",
            invite: "Earn 10% of the Klay used to compound from anyone who uses your referral link",
            "COPY LINK": "COPY LINK",
            "Buy Duck": "Buy Duck",
            now: "now!",
            des1: " The Klay reward pool with the richest daily return and lowest dev fee, daily income of up to 8%, and a referral bonus of up to 10%",
            des2: "#1 - BUY DUCK: Start by using your Klay to purchase ducks.",
            des3: `#2 - COMPOUND: To maximize your earnings, click on the "COMPOUND" button. This action will automatically reinvest your rewards back into DUCK.`,
            des4: "#3 - CLAIM REWARDS: This will transfer your accumulated Klay rewards directly into your wallet",
            des5: "The key to maximizing your rewards lies in the quantity of ducks you hold and how frequently you compound them. The more ducks you accumulate and the more often you reinvest your rewards, the greater the potential for earning more rewards",
        },
    },
    ko: {
        translation: {
            Copied: "복사됨!",
            Documentation: "문서화",
            TVL: "TVL",
            Contract: "계약",
            "My Wallet": "내 지갑",
            "Your Ducks": "당신의 오리들",
            DUCKS: "오리",
            "Your Rewards": "당신의 보상들",
            COMPOUND: "복합",
            CLAIM: "청구",
            "Nutritional Values": "영양가",
            "Daily Return": "일일 수익",
            "Dev Fee": "개발자 수수료",
            "Referral Link": "추천 링크",
            invite: "당신의 추천 링크를 사용해서 복합하는 데 사용된 BNB의 10%를 벌어라",
            "COPY LINK": "링크 복사하기",
            "Buy Duck": "오리 구매하기",
            now: "지금!",
            des1: "가장 높은 일일 수익과 가장 낮은 개발 수수료를 가진 클레이 보상 풀, 하루 최대 8%의 수익과 최대 10%의 추천 보너스",
            des2: "#1 - 오리 구매: 클레이를 사용하여 오리를 구매하십시오.",
            des3: `#2 - 복합: 수익을 극대화하려면 "복합" 버튼을 클릭하십시오. 이 작업은 자동으로 보상을 DUCK에 다시 투자합니다.`,
            des4: "#3 - 보상 청구: 이것은 누적된 클레이 보상을 직접 지갑으로 전송합니다",
            des5: "보상을 극대화하는 열쇠는 당신이 가지고 있는 오리의 양과 그들을 얼마나 자주 복합하는지에 달려 있습니다. 더 많은 오리를 축적하고 보상을 재투자하는 빈도가 높을수록 더 많은 보상을 얻을 가능성이 커집니다.",
        },
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
