import { SecondaryButton } from "@/components/Button";
import { shortenAddress } from "@/utils";
import { Box } from "@chakra-ui/react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import React from "react";
import { useAccount, useChainId, useWalletClient } from "wagmi";

const ConnectButton = () => {
    const { open } = useWeb3Modal();
    const { address, isConnected } = useAccount({});
    return (
        <Box>
            <SecondaryButton
                onClick={() => {
                    open();
                }}
            >
                {address ? shortenAddress(address, 4, 6) : "Connect Wallet"}
            </SecondaryButton>
        </Box>
    );
};

export default ConnectButton;
