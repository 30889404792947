import { Input, InputProps } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import styled from "@emotion/styled";

const PrimaryInputStyle = styled(Input)`
    background: transparent;
    color: #fff;
    height: 48px;
    padding: 0 12px;
`;

const PrimaryInput = ({ ...rest }: InputProps): ReactElement => {
    return (
        <PrimaryInputStyle variant={"unstyled"} {...rest}></PrimaryInputStyle>
    );
};

export default PrimaryInput;
