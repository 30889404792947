import { Button, ButtonProps, Image, chakra } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import DuckIcon from "@/assets/duck-icon.svg";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const PrimaryButtonStyle = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 24px 12px;
    color: white;
    text-shadow: 2px 2px rgb(116, 116, 116);
    text-transform: uppercase;
    border: solid 2px black;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 18px;
    background-color: #fcd835;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease;

    &:hover {
        img {
            transform: scale(2.5) translate(50%);
            transition: all 0.5s ease;
        }
        .now {
            transform: translateX(10px);
            transition-delay: 300ms;
        }
        .play {
            transform: translateX(200%);
            transition-delay: 300ms;
        }
    }
    &:active {
        transform: scale(0.9);
        transition: all 100ms ease;
    }
`;

const PrimaryButton = ({ ...rest }: ButtonProps): ReactElement => {
    const { t } = useTranslation();
    return (
        <PrimaryButtonStyle {...rest} variant={"unstyled"}>
            <Image
                src={DuckIcon}
                sx={{
                    width: "40px",
                    transition: "transform 0.5s ease",
                }}
            ></Image>
            <chakra.span
                className="now"
                position="absolute"
                left="0"
                transform="translateX(-100%)"
                transition="all .5s ease"
                zIndex="2"
            >
                {t("now")}
            </chakra.span>
            <chakra.span
                className="play"
                transition="all .5s ease"
                transitionDelay="300ms"
            >
                {t("Buy Duck")}
            </chakra.span>
        </PrimaryButtonStyle>
    );
};

const SecondaryButtonStyle = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 24px 12px;
    color: #fcd835;
    text-shadow: 1px 1px rgb(58, 58, 58);
    text-transform: uppercase;
    border: solid 2px #fcd835;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 18px;
    background-color: #302d38;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease;

    &:hover {
        background-color: #171717;
        color: white;
        img {
            transform: scale(2) translate(40%);
            transition: all 0.5s ease;
        }
        .now {
            transform: translateX(10px);
            transition-delay: 300ms;
        }
        .play {
            transform: translateX(200%);
            transition-delay: 300ms;
        }
    }
    &:active {
        transform: scale(0.9);
        transition: all 100ms ease;
    }
`;

export const SecondaryButton = ({ ...rest }: ButtonProps): ReactElement => {
    return (
        <SecondaryButtonStyle
            {...rest}
            variant={"unstyled"}
        ></SecondaryButtonStyle>
    );
};

export default PrimaryButton;
