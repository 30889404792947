import {
    Box,
    Flex,
    Grid,
    GridItem,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useMediaQuery,
} from "@chakra-ui/react";
import React, { ReactElement, useEffect } from "react";
import ProjectInfo from "@/components/ProjectInfo";
import AbountMe from "@/components/AboutMe";
import { NormalCard, Card } from "@/components/Card";
import NutriValues from "@/components/NutriValues";
import Referral from "@/components/Referral";
import ConnectButton from "@/components/ConnectButton";
import { useTranslation } from "react-i18next";
import { SecondaryButton } from "@/components/Button";
import { useChainId, useWalletClient } from "wagmi";

const Activities = (): ReactElement => {
    const { i18n } = useTranslation();

    const [isLargerThan800] = useMediaQuery("(min-width: 1200px)");

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        localStorage.setItem("language", language);
    };

    useEffect(() => {
        const language = localStorage.getItem("language");
        if (language === "ko") {
            changeLanguage("ko");
        } else {
            changeLanguage("en");
        }
    }, []);

    return (
        <Box
            w="100vw"
            sx={{
                background: "rgb(12,12,12)",
                padding: "40px 0",
                minHeight: "100vh",
            }}
        >
            <Box
                sx={{
                    width: isLargerThan800 ? "1200px" : "90%",
                    margin: "0 auto",
                    borderRadius: "20px",
                }}
            >
                <Flex justify={"flex-end"}>
                    <Menu>
                        <MenuButton borderRadius={"10px"} marginRight={"10px"}>
                            <SecondaryButton>{i18n.language}</SecondaryButton>
                        </MenuButton>
                        <MenuList
                            sx={{
                                border: "1px solid #fcd835",
                                padding: "0px",
                                background: "#171717 important",
                                minWidth: "100px !important",
                                overflow: "hidden",
                                zIndex: "9999",
                                "&>button:focus": {
                                    background: "#fcd835",
                                },
                            }}
                        >
                            <MenuItem
                                sx={{}}
                                onClick={() => {
                                    changeLanguage("en");
                                }}
                            >
                                <Flex sx={{}}>
                                    <Text sx={{ width: "80px" }}>English</Text>
                                    <Text>EN</Text>
                                </Flex>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    changeLanguage("ko");
                                }}
                            >
                                <Flex sx={{}}>
                                    <Text sx={{ width: "80px" }}>한국어</Text>
                                    <Text>KO</Text>
                                </Flex>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    <ConnectButton></ConnectButton>
                </Flex>
                {isLargerThan800 ? (
                    <Grid
                        h="880px"
                        templateRows="repeat(7, 1fr)"
                        templateColumns="repeat(7, 1fr)"
                        gap={4}
                        sx={{
                            marginTop: "40px",
                        }}
                    >
                        <GridItem rowSpan={7} colSpan={4}>
                            <NormalCard
                                sx={{
                                    height: "100%",
                                }}
                            >
                                <ProjectInfo></ProjectInfo>
                            </NormalCard>
                        </GridItem>
                        <GridItem rowSpan={3} colSpan={3}>
                            <Card
                                sx={{
                                    height: "100%",
                                }}
                            >
                                <AbountMe></AbountMe>
                            </Card>
                        </GridItem>
                        <GridItem rowSpan={2} colSpan={3}>
                            <NormalCard
                                sx={{
                                    height: "100%",
                                }}
                            >
                                <NutriValues></NutriValues>
                            </NormalCard>
                        </GridItem>
                        <GridItem rowSpan={2} colSpan={3}>
                            <NormalCard
                                sx={{
                                    height: "100%",
                                }}
                            >
                                <Referral></Referral>
                            </NormalCard>
                        </GridItem>
                    </Grid>
                ) : (
                    <Box
                        sx={{
                            "&>div": {
                                marginTop: "20px",
                            },
                        }}
                    >
                        <NormalCard>
                            <ProjectInfo></ProjectInfo>
                        </NormalCard>
                        <Card>
                            <AbountMe></AbountMe>
                        </Card>
                        <NormalCard>
                            <NutriValues></NutriValues>
                        </NormalCard>
                        <NormalCard
                            sx={{
                                height: "100%",
                            }}
                        >
                            <Referral></Referral>
                        </NormalCard>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default Activities;
