import { useMemo } from "react";
import GAME_ABI from "@/skyConstants/abis/Game.json";

import { ChainId } from "@/utils/web3Utils";
import { useChainId, useWalletClient } from "wagmi";
import { getContract } from "@/utils/contractHelpers";

type ChainIdToAddressMap = { [chainId: number]: `0x${string}` };

export const gameAddress: ChainIdToAddressMap = {
    [ChainId.BSCTESTNET]: "0x7c93617BD7DF1C488480BCB43eda6a3D3faf1d7D",
    [ChainId.KLAYTN]: "0x3b385af11f8b29a7878cdc3f22ecb4bace36fca3",
};

function useContract(address: any, abi: any) {
    const chainId = useChainId();
    const { data: walletClient } = useWalletClient({
        chainId,
    });

    return useMemo(() => {
        if (!address || !abi) return null;
        try {
            return getContract({
                abi,
                address,
                chainId,
                signer: walletClient ?? undefined,
            });
        } catch (error) {
            console.error("Failed to get contract", error);
            return null;
        }
    }, [address, abi, chainId, walletClient]);
}

export const useGameContract = () => {
    const chainId = useChainId();
    return useContract(gameAddress[chainId], GAME_ABI);
};
