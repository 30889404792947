import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import Duck from "@/assets/duck.png";
import Tw from "@/assets/twitter.svg";
import Telegram from "@/assets/telegram.svg";
import { useTranslation } from "react-i18next";

const ProjectInfo = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                color: "#E3E3E3",
            }}
        >
            <Image
                src={Duck}
                sx={{
                    width: "240px",
                    display: "block",
                    margin: "0 auto",
                    marginBottom: "15px",
                }}
            />
            <Text
                sx={{
                    fontSize: "20px",
                    marginBottom: "15px",
                    lineHeight: "1.5",
                    color: "#E3DDA2FF",
                }}
            >
                {t("des1")}
                <Button
                    colorScheme="teal"
                    variant="link"
                    onClick={() => {
                        window.open("https://docs.klayminer.site/", "_blank");
                    }}
                >
                    (Documentation)
                </Button>{" "}
            </Text>
            <Box
                sx={{
                    fontSize: "16px",
                    marginBottom: "15px",
                    lineHeight: "1.5",
                    color: "#E3E3E3",
                }}
            >
                <Text sx={{ marginBottom: "10px" }}>{t("des2")}</Text>
                <Text sx={{ marginBottom: "10px" }}>{t("des3")}</Text>
                <Text sx={{ marginBottom: "10px" }}>{t("des4")}</Text>
            </Box>
            <Text
                sx={{
                    fontSize: "18px",
                    lineHeight: "1.6",
                    color: "#E3E3E3",
                }}
            >
                {t("des5")}
            </Text>

            <Flex
                justify={"center"}
                sx={{
                    marginTop: "20px",
                }}
            >
                <Image
                    onClick={() => {
                        window.open("https://twitter.com/KlayMiner", "_blank");
                    }}
                    src={Tw}
                    sx={{
                        width: "48px",
                        cursor: "pointer",
                        marginRight: "20px",
                    }}
                ></Image>
                <Image
                    onClick={() => {
                        window.open("https://t.me/Klayminer", "_blank");
                    }}
                    src={Telegram}
                    sx={{
                        cursor: "pointer",
                        width: "48px",
                    }}
                ></Image>
            </Flex>
        </Box>
    );
};

export default ProjectInfo;
