import { Box, Flex, Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const NutriValues = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <Box sx={{ color: "#A4966B" }}>
            <Text
                sx={{
                    fontSize: "24px",
                    fontWeight: "700",
                }}
            >
                {t("Nutritional Values")}
            </Text>
            <Box
                sx={{
                    marginTop: "16px",
                }}
            >
                <Flex justify="space-between" sx={{ marginBottom: "16px" }}>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        {t("Daily Return")}
                    </Text>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        8%
                    </Text>
                </Flex>
                <Flex justify="space-between" sx={{ marginBottom: "16px" }}>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        APR
                    </Text>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        2920%
                    </Text>
                </Flex>
                <Flex justify="space-between" sx={{ marginBottom: "16px" }}>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        {t("Dev Fee")}
                    </Text>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        1%
                    </Text>
                </Flex>
            </Box>
        </Box>
    );
};

export default NutriValues;
