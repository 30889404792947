import { Box, Flex, Text, useClipboard } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { SecondaryButton } from "@/components/Button";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const AboutMe = (): ReactElement => {
    const { address, isConnected } = useAccount();
    const { t } = useTranslation();

    const copytext = origin + "/#/?invite=" + address;

    const { onCopy } = useClipboard(copytext ?? "");

    const handleCopy = () => {
        if (!address) {
            return;
        }
        onCopy();
        toast.success(t("Copied"));
    };

    return (
        <Box
            sx={{
                color: "#A4966B",
            }}
        >
            <Text
                sx={{
                    fontSize: "24px",
                    fontWeight: "700",
                }}
            >
                {t("Referral Link")}
            </Text>
            <Flex sx={{ margin: "20px 0" }}>
                <Flex
                    sx={{
                        flex: 1,
                        border: "1px solid #9e9e9e",
                        borderRadius: "10px",
                        marginRight: "20px",
                        overflowX: "auto",
                        height: "50px",
                        wordBreak: "break-all",
                        whiteSpace: "nowrap",
                    }}
                    align={"center"}
                    paddingRight={"10px"}
                >
                    <Text
                        sx={{
                            fontSize: "18px",
                            fontWeight: "600",
                        }}
                    >
                        {isConnected && origin + "/#/?invite=" + address}
                    </Text>
                </Flex>
                <SecondaryButton onClick={handleCopy}>
                    <Text sx={{ fontSize: "16px", fontWeight: "500" }}>
                        {t("COPY LINK")}
                    </Text>
                </SecondaryButton>{" "}
            </Flex>
            <Text>{t("invite")}</Text>
        </Box>
    );
};

export default AboutMe;
