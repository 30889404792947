import { Box, Flex, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import React, { ReactElement, useEffect, useState } from "react";
import PrimaryButton, { SecondaryButton } from "@/components/Button";
import PrimaryInput from "@/components/PrimaryInput";
import { useAccount, useBalance, useChainId, useWalletClient } from "wagmi";
import { gameAddress, useGameContract } from "@/hooks/useContract";
import {
    accMul,
    formatAmount,
    parseAmount,
    toFixed,
} from "@/utils/formatBalance";
import qs from "query-string";
import { isAddress } from "@/utils/isAddress";
import { useCountUp } from "react-countup";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { handleError } from "@/utils/error";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
const AboutMe = (): ReactElement => {
    const { t } = useTranslation();
    const countUpRef = React.useRef(null);
    const chainId = useChainId();
    const { data: walletClient } = useWalletClient({
        chainId,
    });

    const { start, update } = useCountUp({
        ref: countUpRef,
        start: 0,
        end: 0,
        suffix: " Klay",
        decimals: 8,
        onReset: () => console.log("Resetted!"),
        onUpdate: () => console.log("Updated!"),
        onPauseResume: () => console.log("Paused or resumed!"),
        // onStart: ({ pauseResume }) => console.log(pauseResume),
        // onEnd: ({ pauseResume }) => console.log(pauseResume),
    });

    useEffect(() => {
        start();
    }, []);

    const { search } = useLocation();
    const params = qs.parse(search) as any;
    const [buyAmount, setBuyAmount] = useState("0");
    const [duckAmount, setDuckAmount] = useState("0");
    const [rewardAmount, setRewardAmount] = useState("0");
    const gameContract = useGameContract();
    const { data } = useBalance({
        address: walletClient?.account?.address,
    });

    const { data: gameData } = useBalance({
        address: gameAddress[chainId],
    });

    const handleGetMy = async () => {
        const amount = await gameContract.read.getMyEggs([
            walletClient?.account?.address,
        ]);
        setDuckAmount(amount.toString());
    };

    const handleGetMyRewards = async () => {
        const amount = await gameContract.read.getMyEggs([
            walletClient?.account?.address,
        ]);
        if (amount.toString() === "0") {
            update("0");
            setRewardAmount("0");
            return;
        }
        const reward = await gameContract.read.calculateEggSell([
            amount.toString(),
        ]);

        const formattedAmount = formatAmount(reward.toString(), 18);
        update(formattedAmount);

        setRewardAmount(formattedAmount);
    };

    const handleBuyDuck = async () => {
        if (!walletClient) {
            return;
        }
        try {
            if (buyAmount === "0" || buyAmount === "") {
                return;
            }

            const invite = isAddress(params.invite)
                ? isAddress(params.invite)
                : walletClient?.account?.address;

            const amount = parseAmount(buyAmount, 18);
            const gas = await gameContract.estimateGas.buyEggs([invite], {
                value: amount,
            });
            const tx = await gameContract.write.buyEggs([invite], {
                value: amount,
            });
            handleGetMy();
        } catch (e) {
            toast.error(handleError(e));
        }
    };

    const handleClaim = async () => {
        if (!walletClient?.account?.address) {
            return;
        }
        try {
            const gas = await gameContract.estimateGas.sellEggs([]);
            const tx = await gameContract.write.sellEggs([]);
            handleGetMy();
            handleGetMyRewards();
        } catch (e) {
            toast.error(handleError(e));
        }
    };

    const handleCompound = async () => {
        if (!walletClient?.account?.address) {
            return;
        }
        try {
            const invite = isAddress(params.invite)
                ? isAddress(params.invite)
                : walletClient?.account?.address;

            const gas = await gameContract.estimateGas.hatchEggs([invite]);
            const tx = await gameContract.write.hatchEggs([invite], {});
            handleGetMy();
        } catch (e) {
            toast.error(handleError(e));
        }
    };

    useEffect(() => {
        if (!gameContract || !walletClient) return;
        handleGetMy();
        handleGetMyRewards();
        const timer = setInterval(() => {
            handleGetMy();
            handleGetMyRewards();
        }, 6000);

        return () => {
            clearInterval(timer);
        };
    }, [gameContract, walletClient]);

    return (
        <Box
            sx={{
                height: "100%",
                color: "#A4966B",
                fontFamily: "Arial, sans-serif", // 示例字体
            }}
        >
            <Box
                sx={{
                    zIndex: 1,
                }}
            >
                {/* <Flex justify="space-between" sx={{ marginBottom: "16px" }}>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        {t("TVL")}
                    </Text>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        {toFixed(accMul(gameData?.formatted, 330), 2)} $
                    </Text>
                </Flex> */}
                <Flex justify="space-between" sx={{ marginBottom: "16px" }}>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        {t("Contract")}
                    </Text>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        {toFixed(gameData?.formatted, 4)} Klay
                    </Text>
                </Flex>
                <Flex justify="space-between" sx={{ marginBottom: "16px" }}>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        {t("My Wallet")}
                    </Text>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        {toFixed(data?.formatted, 4)} Klay
                    </Text>
                </Flex>
                <Flex justify="space-between" sx={{ marginBottom: "16px" }}>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        {t("Your Ducks")}
                    </Text>
                    <Text
                        sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "1.4",
                        }}
                    >
                        {duckAmount} {t("DUCKS")}
                    </Text>
                </Flex>
                <Flex sx={{ marginBottom: "20px" }}>
                    <Flex
                        sx={{
                            flex: 1,
                            border: "1px solid #9e9e9e",
                            borderRadius: "10px",
                            marginRight: "20px",
                        }}
                        align={"center"}
                        paddingRight={"10px"}
                    >
                        <PrimaryInput
                            value={buyAmount}
                            onChange={(e) => setBuyAmount(e.target.value)}
                            sx={{
                                textAlign: "right",
                                color: "#A4966B",
                                fontSize: "18px",
                            }}
                        />
                        <Text
                            sx={{
                                fontSize: "18px",
                                fontWeight: "600",
                            }}
                        >
                            Klay
                        </Text>
                    </Flex>

                    <PrimaryButton onClick={handleBuyDuck} />
                </Flex>
                <Box sx={{ marginTop: "20px" }}>
                    <Flex justify="space-between" sx={{ marginBottom: "10px" }}>
                        <Text
                            sx={{
                                fontSize: "18px",
                                fontWeight: "600",
                                lineHeight: "1.5",
                            }}
                        >
                            {t("Your Rewards")}
                        </Text>
                        <Text
                            sx={{
                                fontSize: "18px",
                                fontWeight: "600",
                                lineHeight: "1.5",
                            }}
                            ref={countUpRef}
                        ></Text>
                    </Flex>
                    <SimpleGrid columns={2} gap={4}>
                        <GridItem>
                            <SecondaryButton
                                onClick={handleCompound}
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <Text
                                    sx={{ fontSize: "16px", fontWeight: "500" }}
                                >
                                    {t("COMPOUND")}
                                </Text>
                            </SecondaryButton>
                        </GridItem>

                        <GridItem>
                            <SecondaryButton
                                onClick={handleClaim}
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <Text
                                    sx={{ fontSize: "16px", fontWeight: "500" }}
                                >
                                    {t("CLAIM")}
                                </Text>
                            </SecondaryButton>
                        </GridItem>
                    </SimpleGrid>
                </Box>
            </Box>
        </Box>
    );
};

export default AboutMe;
