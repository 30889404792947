import "swiper/css/bundle";
import { Box } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = ({ children }: React.PropsWithChildren<unknown>): ReactElement => {
    return (
        <Box minH="100vh" bg="black" color="white">
            {children}
            <Outlet />
            <ToastContainer></ToastContainer>
        </Box>
    );
};

export default App;
