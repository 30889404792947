/** SUPPORTED CHAINS */
export enum ChainId {
    ETHEREUM = 1,
    POLYGON = 137,
    BSC = 56,
    BSCTESTNET = 97,
    MUMBAI = 80001,
    BASE = 8453,
    BASEGOERLI = 84531,
    KLAYTN = 8217,
}
