import React, { ReactElement, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import App from "./App";
import Activities from "./pages/Activities";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <></>;
};

const AppRoutes = (): ReactElement => {
    const { selectedNetworkId, open: isOpen } = useWeb3ModalState();
    const { open, close } = useWeb3Modal();

    useEffect(() => {
        if ((selectedNetworkId as any) !== 8217) {
            open();
        }
    }, [selectedNetworkId]);

    return (
        <Routes>
            <Route path="/" element={<App />}>
                <Route index element={<Activities />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
